import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  type ReactNode,
} from 'react';
import { useMedia, useIdle } from 'react-use';
import { BreakpointQueryLarge } from '@hungryroot/tokens';

const scrollSpeedCalculator = () => {
  const delay = 50;
  let lastPosition: number | null,
    newPosition: number,
    timer: ReturnType<typeof setTimeout>,
    delta: number;
  function clear() {
    lastPosition = null;
    delta = 0;
  }

  clear();
  return function () {
    newPosition = window.scrollY;
    if (lastPosition != null && newPosition < lastPosition) {
      delta = lastPosition - newPosition;
    }
    lastPosition = newPosition;
    clearTimeout(timer);
    timer = setTimeout(clear, delay);
    return delta;
  };
};

export type Props = {
  idleDelayMilliseconds: number;
  children: ReactNode;
  onShow: () => void;
};

/*
 This component is responsible for hiding the modal on interactions
 The interactivity rules for hiding the Exit Intent Modal is *complex*.

 Added to a separate component to try to hide the complexity.

 These are the desired triggers:

 1. When user backgrounds browser app (mobile only)
 2. When user mouses off of page (desktop only)
 3. When user backgrounds tab
 4. When user is inactive for 15 seconds
 5. When user clicks browser back button to leave hungryroot site (currently indirectly covered by onBlur)

*/
export function VisibilityController({
  onShow,
  children,
  idleDelayMilliseconds,
}: Props) {
  const [{ previousVisibility, currentVisibility }, setVisibilityState] =
    useState<{
      previousVisibility: boolean | null;
      currentVisibility: boolean;
    }>({
      previousVisibility: null,
      currentVisibility: false,
    });

  // for when the user is idle
  const idle = useIdle(idleDelayMilliseconds);
  const isDesktop = useMedia(BreakpointQueryLarge);

  const setVisibility = useCallback(
    (value: boolean) => {
      if (currentVisibility === true) {
        return;
      }
      setVisibilityState({
        previousVisibility: currentVisibility,
        currentVisibility: value,
      });
    },
    [currentVisibility, setVisibilityState]
  );

  useEffect(() => {
    if (idle !== true) {
      return;
    }

    setVisibility(true);
  }, [setVisibility, idle]);

  useEffect(() => {
    if (isDesktop === true || idle === false) {
      return;
    }

    let onScroll: () => void;
    const mobileScrollEvent = function () {
      const scrollSpeed = scrollSpeedCalculator();
      onScroll = () => {
        if (scrollSpeed() > 50) {
          setVisibility(true);
        }
      };
      document.addEventListener('scroll', onScroll, { passive: true });
    };

    // to prevent some weird:
    const timeout = setTimeout(mobileScrollEvent, 4000);

    return () => {
      clearTimeout(timeout);
      document.removeEventListener('scroll', onScroll);
    };
  }, [idle, setVisibility, isDesktop]);

  useEffect(() => {
    function onBlur() {
      setVisibility(true);
    }

    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, [setVisibility]);

  // for when a user mouses off page, or backgrounds the tab (desktop or mobile)
  useEffect(() => {
    function onBlur() {
      setVisibility(true);
    }

    document.addEventListener('mouseleave', onBlur);

    return () => {
      document.removeEventListener('mouseleave', onBlur);
    };
  }, [setVisibility]);

  useLayoutEffect(() => {
    if (currentVisibility === true && previousVisibility === false) {
      onShow();
    }
  }, [onShow, currentVisibility, previousVisibility]);

  if (currentVisibility === true) {
    return <>{children}</>;
  }

  return null;
}
