import { useEffect, type ReactNode } from 'react';

import {
  getFormProps,
  getInputProps,
  useForm,
  useInputControl,
} from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';
import { z } from 'zod';
import { TextInput, Paragraph } from '@hungryroot/ramen';
import { type Lead, LeadSource } from '@hungryroot/rest';
import styles from './PhoneInput.module.css';

export const PhoneNumberSchema = z
  .string({
    required_error: 'Enter a valid phone number',
  })
  .regex(/^\(\d{3}\) \d{3}-\d{4}$/, {
    message: 'Enter a valid phone number',
  });

// this was copied over from AddressForm utils, not sure where to put it
export function stripChars(str: string) {
  return str.replace(/[^A-Z0-9]/gi, '');
}

export interface PhoneInputProps {
  onSubmit: (data: Lead) => Promise<unknown>;
  children: ReactNode;
}

export const PhoneNumberLeadSchema = z.object({
  phoneNumber: PhoneNumberSchema,
});

export const PhoneInput = (props: PhoneInputProps) => {
  const { onSubmit, children } = props;

  useEffect(() => {
    analytics.track('Exit Modal with Phone Number');
  }, []);

  const [form, fields] = useForm({
    id: 'phone-input-lead-form',
    constraint: getZodConstraint(PhoneNumberLeadSchema),
    defaultValue: {
      phoneNumber: '',
    },
    shouldValidate: 'onBlur',
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: PhoneNumberLeadSchema });
    },
    onSubmit: async (e, { formData }) => {
      e.preventDefault();

      const submission = parseWithZod(formData, {
        schema: PhoneNumberLeadSchema,
      });

      if (submission.status !== 'success') {
        return;
      }
      const response = await onSubmit({
        phone_number: stripChars(submission.value.phoneNumber),
        source: LeadSource.EXIT_INTENT_MODAL,
      });

      return response;
    },
  });

  const phoneNumber = useInputControl(fields.phoneNumber);

  return (
    <form {...getFormProps(form)}>
      <TextInput
        className={styles.input}
        inputProps={getInputProps(fields.phoneNumber, {
          type: 'tel',
        })}
        type="tel"
        label="Phone number"
        value={phoneNumber.value || ''}
        errorMessage={fields.phoneNumber.errors}
        onChange={phoneNumber.change}
      />
      <Paragraph className={styles.disclosure}>
        By entering your phone number, you agree to receive personalized deals
        and other messages via text from Hungryroot. Message & data rates may
        apply. Reply HELP for help and STOP to cancel. View{' '}
        <a className={styles.link} href="/terms/" target="_blank">
          Terms
        </a>{' '}
        &amp;{' '}
        <a className={styles.link} href="/privacy/" target="_blank">
          Privacy
        </a>
      </Paragraph>
      {children}
    </form>
  );
};
